
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'
import Input from '~/components/Input/Input.vue'
import InputTextarea from '@/components/StudyCreation/InputTextarea.vue'
export default defineComponent({
  name : 'AddCheckpointModal',
  components:{
    Button,
    Input,
    InputTextarea
  },
  computed:{
    formEnabled(){
      const checkpoint: any = this.checkpoint
      return checkpoint.type != '' && checkpoint.name.length > 0 && checkpoint.message.length > 0 && (checkpoint.elementSelector.length > 0 || checkpoint.checkUrl.length > 0)
    }
  },
  data(){
    return{
      checkpoint:{
        type:'',
        name:'',
        message:'',
        elementSelector:'',
        checkUrl: ''
      },
      created: false,

    }
  },
  methods:{
    close(){
      const vm = this
      this.created = false
      setTimeout(function(){
        vm.$emit('close')
      }, 200)
    },
    add(){
      const checkpoint = {
        type : this.checkpoint.type,
        name : this.checkpoint.name,
        message : this.checkpoint.message,
        elementSelector : this.checkpoint.type == 'URL_LOAD' ? null : this.checkpoint.elementSelector,
        checkUrl: this.checkpoint.type != 'URL_LOAD' ? null : this.checkpoint.checkUrl
      }
      this.$emit('add-checkpoint', checkpoint)
      this.close()
    }
  },
  mounted(){
    this.created = true
  }
})
