
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name : 'FigmaIframeModal',
    props:{
      url:String
    },
    computed:{
      urlBase(){
        if(!this.url?.includes('figma')) return ''
        const figmaUrlBase = 'https://www.figma.com/embed?embed_host=share&url='
        return figmaUrlBase
      }
    },
    data(){
      return{
        showIframe: false
      }
    },
    methods:{
      close(){
        this.showIframe = false
        const vm = this
        setTimeout(
        function() {
          vm.$emit('close')
        }, 500);
      }
    },
    mounted(){
      this.showIframe = true
    }
  })
  