
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'
import Separation from '@/components/StudyCreation/Separation.vue'
import SwitchSelector from '~/components/SwitchSelector/SwitchSelector.vue'
export default defineComponent({
  name : 'TaskRandomModal',
  components:{
    Button,
    Separation,
    SwitchSelector
  },
  props:{
    tasks:{
      type: Array
    }
  },
  data(){
    return{
      created: false,
      setRandom: true
    }
  },
  methods:{
    close(){
      const vm = this
      this.created = false
      setTimeout(function(){
        vm.$emit('close')
      }, 200)
    },
    change(){
      if(this.setRandom == false) this.tasks?.map((t: any) => t.randomOrder = false)
    }
  },
  mounted(){
    this.created = true
  }
})
