<template lang="html">
  <div class="Dropzone">
    <input :ref="'Dropzone-' + _uid" type="file" v-on:change="fileSelected($event)" :accept="acceptedFiles" v-show="maxFiles > files.length">
    <div class="Dropzone--Message" v-show="files.length == 0" v-html="uploadText">
    </div>
    <div class="Dropzone--Files">
      <div class="Dropzone--File" :class="{'Dropzone--NoSrc':getFileType(file.type) != 'IMAGE'}" v-for="(file, index) in files">
        <div class="Dropzone--Image">
          <img :src="file.src">
        </div>
        <div class="Dropzone--Uploading" v-show="file.status == 'NEW'">
          <div class="Dropzone--UploadBar">
            <div class="Dropzone--Progress" :style="{'width':file.progress+'%'}">

            </div>
          </div>
        </div>
        <div class="Dropzone--Details">
          <span>{{file.name}}</span>
          <div class="Dropzone--DetailsFooter">
            <span>{{getSizeinKb(file.size)}} Mb</span>
            <i class="fas fa-times" v-on:click="removeFile(index)"></i>
          </div>
        </div>
        <div class="Dropzone--Error" v-show="file.status == 'FAILED'">
          <span>{{file.errorMessage}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
import {v4 as uuidv4} from 'uuid';
import axios from 'axios';
import store from '@/store'
export default {
  name: 'Dropzone',

  props: {
    acceptedFiles: String,
    uploadUrl: String,
    uploadHeaders: Object,
    uploadText: String,
    maxFiles:{
      type: Number,
      default: 100
    },
    autoUpload:{
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      _uid:getCurrentInstance().uid,
      files:[],
      progress:0
    }
  },

  methods: {
    fileSelected(event){
      const file = this.$refs['Dropzone-'+this._uid].files[0];
      this.files.push({
        inputData: file,
        src:URL.createObjectURL(file),
        name: file.name,
        size: file.size,
        type: file.type,
        progress: 0,
        status:'NEW',
        uuid: uuidv4()
      })

      this.$emit('file-added', file)

      if(this.autoUpload) this.uploadFile(file, this.files.length - 1)
    },
    processUpload(){
      let index = 0
      for(let file of this.files){
        if(file.status == 'NEW') this.uploadFile(file.inputData, index)
        index++
      }
    },
    async uploadFile(file, index){
      let formData = new FormData();
      formData.append("file", file);
      const response = await axios.post(this.uploadUrl, formData, this.getConfig(index))
      if (response.status != 200) {
        this.files[index].status = 'FAILED'
        this.files[index].errorMessage = 'Error al procesar el archivo'
        this.$emit('file-error', file, response)
        return;
      }
        this.files[index].data = response.data
        this.files[index].status = 'UPLOADED'
        this.$emit('file-uploaded',this.files[index], this.files[index].data)
    },
    removeFile(index){
      const file = this.files[index]
      this.files.splice(index,1)
      this.$emit('file-removed', file)
      this.$refs['Dropzone-'+this._uid].value = null
    },
    getConfig(index){
      let headers = this.uploadHeaders
      headers['Content-Type'] = 'multipart/form-data'
      headers['Accept'] = 'application/json'
      headers['authToken'] = store.getters.authToken
      let config = {
        'withCredentials': true,
        'Access-Control-Allow-Credentials': true,
        headers: headers,
        onUploadProgress: progressEvent => {
          this.files[index].progress = Math.floor((progressEvent.loaded * 100) / progressEvent.total);
        },
      }
      return config
    },
    getFileType(type){
      if(type.includes('video')) return "VIDEO"
      else if(type.includes('image')) return "IMAGE"
      else return "FILE"
    },
    reset(){
      this.files = []
      this.$refs['Dropzone-'+this._uid].value = null
    },
    getSizeinKb(bytes){
        if(bytes == 0){
            return 0
        }
        return (bytes / 1024 / 1024).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
.Dropzone {
  border: 2px solid #f2f2f2;
  letter-spacing: .2px;
  color: #777;
  transition: .2s linear;
  background-color: #f2f2f2;
  min-height: auto;
  position: relative;
  &:hover{
    background-color: #dfdfdf;
    border-color: #dfdfdf;
    cursor: pointer;
  }
  &--Message{
    text-align: center;
    margin: 2em;
    font-size: 16px;
    font-family: Arial;
  }
  &--Files{
    display: flex;
    flex-wrap: wrap;
  }
  &--File{
    position: relative;
    box-shadow: 0 2px 3px rgb(0, 0, 0, 0.1);
    width: 150px;
    height: 100px;
    border-radius: 0.2em;
    margin: 16px;
    &:hover{
      .Dropzone--Details{
        opacity: 1;
        visibility: visible;
      }
      .Dropzone--Error{
        display: flex;
      }
    }
    i{
      font-size: 15px;
    }
  }
  &--Image{
    width: 100%;
    height: 100%;
    border-radius: 0.2em;
    display: block;
    overflow: hidden;
    img{
      border-radius: 0.2em;
      width: -webkit-fill-available;
    }
  }
  &--Details{
    position: absolute;
    visibility: hidden;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity:0;
    background: rgba(0,0,0,0.5);
    transition: 0.2s;
    border-radius: 0.2em;
    color: white;
    font-size: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    span{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &--DetailsFooter{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
  }
  &--NoSrc{
    .Dropzone--Image{
      display: none;
    }
    .Dropzone--Details{
      opacity: 1;
      visibility: visible;
    }
  }
  &--Error{
    position: absolute;
    display: none;
    top:-50%;
    left:0;
    color: white;
    font-size: 13px;
    background: #be2626;
    max-height: 100px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 0.4em;
  }
  input{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    opacity: 0;
    &::-webkit-file-upload-button {
        cursor: pointer;
    }
  }
  &--Uploading{
    position: absolute;
    left: 5%;
    width: 90%;
    z-index: 1;
    top: calc(50% - 5px);
  }
  &--UploadBar{
    width: 100%;
    height: 10px;
    background: lightgray;
    position: relative;
    border-radius: 6px;
  }
  &--Progress{
    width: 0%;
    height: 100%;
    transition: 0.5s;
    background: #33cc33;
    border-radius: 6px;
  }
}
</style>
