
  import { defineComponent, ref } from 'vue';
  import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
  import Separation from '@/components/StudyCreation/Separation.vue'
  export default defineComponent({
    name : 'StudyFigmaUrl',
    components:{
      SuiteInput,
      Separation
    },
    props:{
      startUrlProp:{
        type: String,
        default: ''
      },
      successUrlProp:{
        type: String,
        default: ''
      }
    },
    data(){
      return{
        startUrl:this.startUrlProp,
        successUrl:this.successUrlProp,
        startUrlValidation:{
          success: false,
          error: false
        },
        successUrlValidation: {
          success: false,
          error: false
        },
        messageCorrectUrl:this.$t('suite_study_figma_success'),
        messageErrorUrl:this.$t('suite_study_figma_error')
      }
    },
    watch:{
      startUrl(){
        this.checkFigmaUrl('START',this.startUrl)
      },
      successUrl(){
        this.checkFigmaUrl('SUCCESS',this.startUrl)
      }
    },
    methods:{
      checkFigmaUrl(type: any, url: any){
        const FIGMA_REGEX = /https:\/\/([\w\.-]+\.)?figma.com\/(file|proto)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/
        const match = url.match(FIGMA_REGEX)
        const error = match == null ? true : false
        const success = match != null ? true : false
        if(type == 'START') this.startUrlValidation = { success: success, error: error }
        if(type == 'SUCCESS') this.successUrlValidation = { success: success, error: error }
        this.update()
      },
      update(){
        this.$emit('update', { startUrl: this.startUrl, successUrl:this.successUrl })
      },
      checkUrls(){
        this.checkFigmaUrl('START',this.startUrl)
        this.checkFigmaUrl('SUCCESS',this.startUrl)
      },
      hashErrors(){
        return this.startUrlValidation.error || this.successUrlValidation.error || this.checkUrls()
      }
    }
  })
  