import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelSelect as _vModelSelect, withDirectives as _withDirectives, vShow as _vShow, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b78989d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "AddCheckpointModal" }
const _hoisted_2 = {
  key: 0,
  class: "AddCheckpointModal--Box"
}
const _hoisted_3 = { class: "AddCheckpointModal--Content" }
const _hoisted_4 = { class: "AddCheckpointModal--Group" }
const _hoisted_5 = { value: "CLICK" }
const _hoisted_6 = { value: "CHANGE" }
const _hoisted_7 = { value: "URL_LOAD" }
const _hoisted_8 = { value: "SCROLL" }
const _hoisted_9 = { value: "VIEW" }
const _hoisted_10 = { class: "AddCheckpointModal--Actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_InputTextarea = _resolveComponent("InputTextarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.created)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t('ADD_NEW_CHECKPOINT')), 1),
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_Input, {
                  placeholder: _ctx.$t('suite_study_checkpoint_add_id'),
                  label: _ctx.$t('suite_study_checkpoint_id'),
                  modelValue: _ctx.checkpoint.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checkpoint.name) = $event))
                }, null, 8, ["placeholder", "label", "modelValue"]),
                _createVNode(_component_InputTextarea, {
                  placeholder: _ctx.$t('suite_study_checkpoint_instructions'),
                  label: _ctx.$t('suite_study_checkpoint_instructions_label'),
                  showMaxText: true,
                  max: 150,
                  modelValue: _ctx.checkpoint.message,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.checkpoint.message) = $event))
                }, null, 8, ["placeholder", "label", "modelValue"]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('CHECKPOINT_TYPE')), 1),
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.checkpoint.type) = $event))
                  }, [
                    _createElementVNode("option", _hoisted_5, _toDisplayString(_ctx.$filters.formatCheckpointType('CLICK')), 1),
                    _createElementVNode("option", _hoisted_6, _toDisplayString(_ctx.$filters.formatCheckpointType('CHANGE')), 1),
                    _createElementVNode("option", _hoisted_7, _toDisplayString(_ctx.$filters.formatCheckpointType('URL_LOAD')), 1),
                    _createElementVNode("option", _hoisted_8, _toDisplayString(_ctx.$filters.formatCheckpointType('SCROLL')), 1),
                    _createElementVNode("option", _hoisted_9, _toDisplayString(_ctx.$filters.formatCheckpointType('VIEW')), 1)
                  ], 512), [
                    [_vModelSelect, _ctx.checkpoint.type]
                  ])
                ]),
                _withDirectives(_createVNode(_component_Input, {
                  placeholder: "div#example",
                  label: _ctx.$t('suite_study_checkpoint_type_html'),
                  modelValue: _ctx.checkpoint.elementSelector,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.checkpoint.elementSelector) = $event))
                }, null, 8, ["label", "modelValue"]), [
                  [_vShow, _ctx.checkpoint.type != 'URL_LOAD']
                ]),
                _withDirectives(_createVNode(_component_Input, {
                  label: "Url",
                  modelValue: _ctx.checkpoint.checkUrl,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.checkpoint.checkUrl) = $event))
                }, null, 8, ["modelValue"]), [
                  [_vShow, _ctx.checkpoint.type == 'URL_LOAD']
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_Button, {
                  class: "White",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.close()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('suite_action_cancel')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_Button, {
                  class: "Green",
                  disabled: !_ctx.formEnabled,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.add()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('suite_study_checkpoint_add')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled"])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}