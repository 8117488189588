
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex'
import store from '@/store'
export default defineComponent({
  name : 'StudyCreationBase',
  props:{
    title:{
      type:String,
      default:''
    },
    description:{
      type: String,
      default:''
    },
    activeTab:{
      type: Number,
      default:0
    },
    integratedContent:{
      type: Boolean,
      default: false
    },
    showError:{
      type: Boolean,
      default: false
    },
    steps:{
      type: Array,
      default: () => []
    },
    hideTexts:{
      type: Boolean,
      default: false
    }
  }
})
