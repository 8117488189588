
import { defineComponent } from 'vue';

export default defineComponent({
  name : 'DeviceRecordSelector',
  props:{
    recordScreen: Boolean,
    recordMicrophone: Boolean,
    recordWebcam: Boolean
  },
  methods:{
    change(type: any){  
        const data = {
            recordScreen: type == 'SCREEN' ? !this.recordScreen : this.recordScreen,
            recordMicrophone: type == 'MICRO' ? !this.recordMicrophone : this.recordMicrophone,
            recordWebcam: type == 'WEB' ? !this.recordWebcam : this.recordWebcam
        }      
        this.$emit('update', data)
    }
  }
})
