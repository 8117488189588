
import { defineComponent, inject } from 'vue';
import Button from '../Button.vue'
import store from '@/store'
export default defineComponent({
  name : 'ClickTestConfig',
  components:{
    Button
  },
  props:{
    clickTestData: {
      type: Object,
      default: () => ({ imageUrl: '' }),
    }    
  },
  data(){
    return{
      firstLoad: true,
      drawing: false,
      selected:0,
      startX:0,
      startY:0,
      endX:0,
      endY:0,
      coordsPercent:{
        startX:0,
        startY:0,
        endX:0,
        endY:0
      },
      imageHTML: new Image(),
      imageUrl: '',
      loading: false
    }
  },
  methods:{
    changeMenuSelection(id: any){
      this.selected = id
    },
    generateCanvas(){
      let vm = this
      
      const imageUrl = this.imageUrl;
      this.imageHTML = new Image();
      this.imageHTML.src = imageUrl;

      this.imageHTML.onload = function() {
        vm.loadCanvasData()
      };

      window.addEventListener('resize', function() {
        if(!vm.firstLoad) {
          vm.setCoordsToPx()
          vm.loadCanvasData()
        }
      });
    },
    loadCanvasData() {
      const canvas: any = document.getElementById('ClickTestConfigCanvas');
      const parentContainer: any = document.getElementById('ClickTestConfigContent');
      const ctx: any = canvas.getContext('2d');

      let imageWidth = this.imageHTML.width * 0.96;
      let imageHeight = this.imageHTML.height * 0.96;

      let parentWidth = (parentContainer.clientWidth * 0.96) - 84;
      let parentHeight = (parentContainer.clientHeight * 0.96) - 64;

      const scaleWidth = parentWidth / imageWidth;
      const scaleHeight = parentHeight / imageHeight;
      const scaleFactor = Math.min(scaleWidth, scaleHeight);

      if(scaleFactor < 1) {
        imageWidth = imageWidth * scaleFactor
        imageHeight = imageHeight * scaleFactor
      }

      canvas.width = imageWidth
      canvas.height = imageHeight

      ctx.fillStyle = 'rgba(129, 52, 225, 0.43)';
      ctx.strokeStyle = 'rgb(129, 52, 225)';
      ctx.lineWidth = 3;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(this.imageHTML, 0, 0, canvas.width, canvas.height);

      if(this.firstLoad) this.setCoordsToPx()
      this.firstLoad = false
      ctx.fillRect(this.startX, this.startY, this.endX - this.startX, this.endY - this.startY);
      ctx.strokeRect(this.startX, this.startY, this.endX - this.startX, this.endY - this.startY);

      const additionalRectText = "AREA DE CLICK";

      ctx.fillStyle = 'rgb(129, 52, 225)';
      ctx.fillRect(this.startX - 2, this.startY - 22, 105, 22);

      ctx.fillStyle = 'white';
      ctx.font = '12px Arial';
      ctx.fillText(additionalRectText, this.startX + 5, this.startY - 8);
      
    },
    startDrawing(event: any) {
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      this.drawing = true;
      const rect = canvas.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;
    },
    async drawRectangle(event: any) {
      if (!this.drawing) return;
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      const rect = canvas.getBoundingClientRect();
      this.endX = event.clientX - rect.left;
      this.endY = event.clientY - rect.top;
      await this.loadCanvasData()
      this.setCoords()
    },
    stopDrawing() {
      this.drawing = false;
    },
    forceUploadImage(){
      const input :any = this.$refs.fileInput;
      input.click();
    },
    handleFileSelect(event: any){
      this.loading = true
      const file = event.target.files[0];
      store.dispatch('uploadFile', file != undefined ? file : null)
      .then((data) => {
        this.imageUrl = data.path
        this.removeCoords()
      })
      this.loading = false
    },
    setCoordsToPx(){
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      this.startX = this.coordsPercent.startX * canvas.width
      this.startY = this.coordsPercent.startY * canvas.height
      this.endX = this.coordsPercent.endX * canvas.width
      this.endY = this.coordsPercent.endY * canvas.height
    },
    setCoordsInitial(){
      this.coordsPercent = {
        startX:this.clickTestData.successZoneTL_X,
        startY:this.clickTestData.successZoneTL_Y,
        endX:this.clickTestData.successZoneBR_X,
        endY:this.clickTestData.successZoneBR_Y
      }
    },
    setCoords(){
      const canvas: any = document.getElementById('ClickTestConfigCanvas')
      let rectNew ={
        startX:this.startX,
        startY:this.startY,
        endX:this.endX,
        endY:this.endY
      }

      if (rectNew.startX > rectNew.endX){
        rectNew.startX = this.endX
        rectNew.endX = this.startX
      }

      if (rectNew.startY > rectNew.endY){
        rectNew.startY = this.endY
        rectNew.endY = this.startY
      }

      this.coordsPercent.startX = rectNew.startX / canvas.width
      this.coordsPercent.startY = rectNew.startY / canvas.height
      this.coordsPercent.endX = rectNew.endX / canvas.width
      this.coordsPercent.endY = rectNew.endY / canvas.height
        
    },
    removeCoords(){
      this.startX = 0
      this.startY = 0
      this.endX = 0
      this.endY = 0
      this.coordsPercent = {
        startX:0,
        startY:0,
        endX:0,
        endY:0
      }
      this.generateCanvas()
    },
  },
  mounted(){
    this.$nextTick(() => {
      if(this.clickTestData.imageUrl == null || this.clickTestData.imageUrl == undefined || this.clickTestData.imageUrl.length == 0){
        window.dispatchEvent(new Event('resize'));
        document.getElementById('fileInput')?.addEventListener('change', this.handleFileSelect);
        this.forceUploadImage()
        return
      } 
      this.imageUrl = this.clickTestData.imageUrl
      this.setCoordsInitial()
      this.generateCanvas();
      window.dispatchEvent(new Event('resize'));
      document.getElementById('fileInput')?.addEventListener('change', this.handleFileSelect);
    });
  },
  setup (props) {
    const emitter: any = inject("emitter")

    const save = (data: any, imageUrl: any) => {
      let clickTestData: any = props.clickTestData
      clickTestData.imageUrl = imageUrl
      clickTestData.successZoneTL_X = data.startX
      clickTestData.successZoneTL_Y = data.startY
      clickTestData.successZoneBR_X = data.endX
      clickTestData.successZoneBR_Y = data.endY
      emitter.emit("save-clicktest-data", clickTestData)
    }

    return {
        save
    }
  }
})
