
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue'
import QuestionsGrid from '~/components/SurveyCreation/QuestionsGrid.vue'
export default defineComponent({
  name : 'AddQuestionModal',
  components:{
    Button,
    QuestionsGrid
  },
  data(){
    return{
      created: false,
      questionType: ''
    }
  },
  methods:{
    close(){
      const vm = this
      this.created = false
      setTimeout(function(){
        vm.$emit('close')
      }, 200)
    },
    selectQuestion(event: any){
      this.questionType = event
    },
    add(){
      this.$emit('select-question', this.questionType)
      this.close()
    }
  },
  mounted(){
    this.created = true
  }
})
