
import { defineComponent } from 'vue';
import StudyTab from './StudyTab.vue'
import StudyTask from './StudyTask.vue'
import FigmaIframeModal from '../FigmaIframeModal.vue'
import AddQuestionModal from '@/components/StudyCreation/AddQuestionModal.vue'
import AddCheckpointModal from '@/components/StudyCreation/AddCheckpointModal.vue'
import { createQuestion } from '~/utils/survey-creation.ts'
export default defineComponent({
  name : 'StudyUXTasks',
  components:{
    StudyTab,
    StudyTask,
    FigmaIframeModal,
    AddQuestionModal,
    AddCheckpointModal
  },
  props:{
    tasks:{
      type: Array,
      default: () => []
    },
    navigationRules:{
      type: Array,
      default: () => []
    },
    type: String
  },
  data(){
    return{
      activeTaskIndex: 0,
      showIframe: false,
      iframeUrl: "",
      isModalOpen: false,
      taskIndexToAddQuestion:0,
      isModalCheckpointOpen: false,
      taskIndexToAddCheckpoint: 0
    }
  },
  computed:{
    activeTaskNum(){
      const tasks: any = this.tasks;
      if (this.activeTaskIndex >= 0 && this.activeTaskIndex < tasks.length) {
        return tasks[this.activeTaskIndex].taskNum;
      } else {
        return tasks[tasks.length - 1].taskNum;
      }
    }
  },
  methods:{
    selectTask(index: any){
      this.activeTaskIndex = index
    },
    removeTask(index: any){
      this.selectTask(0)
      this.$emit('remove-task', index)
    },
    openIframe(event: any){
      this.showIframe = true
      this.iframeUrl = event
    },
    hasErrors(){
      let errors = []
      for(let t of this.tasks){
        const task: any = t
        const StudyTask:any = this.$refs['StudyTask-' + task.taskNum]
        const error = StudyTask.hasErrors()
        if(error) errors.push({ taskNum: task.taskNum })
      }
      return errors
    },
    openQuestionModal(index: any){
      if(index != null) this.taskIndexToAddQuestion = index
      this.isModalOpen = !this.isModalOpen
    },
    openCheckpointModal(index: any){
      if(index != null) this.taskIndexToAddCheckpoint = index
      this.isModalCheckpointOpen = !this.isModalCheckpointOpen
    },
    createCheckpoint(checkpoint: any){
      const task: any = this.tasks[this.taskIndexToAddCheckpoint]
      task.uxData.checkpoints.push(checkpoint)
      this.$emit('data-updated')
    },
    createQuestion(type: any){
      const task: any = this.tasks[this.taskIndexToAddQuestion]
      createQuestion(task.questions, type)
      this.$emit('data-updated')
    }
  }
})
