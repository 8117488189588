
  import vueApp from '@/main';
  import { defineComponent, ref, inject } from 'vue';
  import { mount } from '@/utils/mount'
  import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
  import SwitchSelector from '~/components/SwitchSelector/SwitchSelector.vue'
  import RangeSlider from '~/components/RangeSlider/RangeSlider.vue'
  import Separation from '@/components/StudyCreation/Separation.vue'
  import Button from '@/components/Button.vue'
  import QuestionSurvey from '~/components/SurveyCreation/QuestionSurvey.vue'
  import StudyTab from './StudyTab.vue'
  import StudyFigmaUrl from './StudyFigmaUrl.vue';
  import DeviceRecordSelector from './DeviceRecordSelector.vue'
  import ClickTestConfig from './ClickTestConfig.vue'
  import draggable from 'vuedraggable'
  import { formatNavigationRules,
        navigationRulesHasErrors,
        addRule,
        generateNavigationRules,
        duplicateQuestion,
        addOptionMatrix,
        addValue,
        removeRule,
        reorderQuestions,
        canRemoveQuestion,
        removeQuestionValueOption,
      removeQuestionValue} from '~/utils/survey-creation.ts'
  export default defineComponent({
    name : 'StudyTask',
    components:{
        SuiteInput,
        Separation,
        SwitchSelector,
        StudyTab,
        StudyFigmaUrl,
        RangeSlider,
        QuestionSurvey,
        draggable,
        DeviceRecordSelector,
        Button,
        ClickTestConfig
    },
    props: {
      task:{
        type:Object,
        default: () => {}
      },
      navigationRules:{
        type: Array,
        default: () => []
      },
      type: String
    },
    computed:{
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        }
      },
      hasClickTestImage(){
        const task: any = this.task
        if(task.clickTestData == undefined || task.clickTestData == null) return false
        return task.clickTestData.imageUrl != null && task.clickTestData.imageUrl != undefined && task.clickTestData.imageUrl.length > 0
      }
    },
    watch:{
      'task.uxData.initialUrl'(){
        this.checkValidUrl()
      }
    },
    data(){
      return{
        selectedTab: 0,
        enabledMinTime: false,
        messageCorrectUrl:this.$t('suite_study_figma_success'),
        messageErrorUrl:this.$t('suite_study_figma_error'),
        uxUrlInitialError: false,
        uxUrlInitialSuccess: false,
        destroyClickTestInstance: () => {},
        imageHTML: new Image()
      }
    },
    methods:{
      openClickTestConfigInstance(){
        const { el, vNode, destroy } = mount(ClickTestConfig, {
          props: {
            clickTestData:this.task.clickTestData
           },
          app: vueApp
        })
        const app: any = vueApp
        app._container.appendChild(el);
        this.destroyClickTestInstance = destroy 
      },
      copyGTMCode(){
        const code: any = document.getElementById("GTM_CODE");
        let body = document.body, range, sel: any
        range = document.createRange()
        sel = window.getSelection()
        sel.removeAllRanges()
        range.selectNodeContents(code)
        sel.addRange(range)
        let selected = window.getSelection()?.toString();
        document.execCommand("copy");
        sel.removeRange(range)
        $(".copy-code-message").show()
        setTimeout(() => {
          $(".copy-code-message").hide()
        }, 2000)
      },
      checkValidUrl(){
        const url = this.task.uxData.initialUrl
        const pattern = new RegExp('^(https?:\\/\\/)?'+
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
          '(\\#[-a-z\\d_]*)?$','i'); 
        const isValidUrl = !!(pattern.test(url) && url.includes('http'))
        this.uxUrlInitialError = !isValidUrl
        this.uxUrlInitialSuccess = isValidUrl
      },
      updateDesktopUrls(event: any){
        this.task.uxQuantiData.desktopStartUrl = event.startUrl
        this.task.uxQuantiData.desktopSuccessUrls[0] = {name:'',url:event.successUrl}
        this.dataIsUpdated()
      },
      updateMobileUrls(event: any){
        this.task.uxQuantiData.mobileStartUrl = event.startUrl
        this.task.uxQuantiData.mobileSuccessUrls[0] = {name:'',url:event.successUrl}
        this.dataIsUpdated()
      },
      updateRecordDevices(devices: any){
        this.task.uxData.recordScreen = devices.recordScreen
        this.task.uxData.recordMicrophone = devices.recordMicrophone
        this.task.uxData.recordWebcam = devices.recordWebcam
      },
      getDesktopUrl(task: any) {
        if (task && task.uxQuantiData && task.uxQuantiData.desktopSuccessUrls && task.uxQuantiData.desktopSuccessUrls.length > 0) {
            return task.uxQuantiData.desktopSuccessUrls[0].url;
        } else {
            this.task.uxQuantiData = {
              desktopStartUrl: '',
              desktopSuccessUrls: [],
              mobileStartUrl: '',
              mobileSuccessUrls: [],
              maxTime: 1,
              minTime: 1
            }
            task.uxQuantiData.desktopSuccessUrls[0] = {name:'',url:''}
            return task.uxQuantiData.desktopSuccessUrls[0].url;
        }
      },
      getMobileUrl(task: any) {
        if (task && task.uxQuantiData && task.uxQuantiData.mobileSuccessUrls && task.uxQuantiData.mobileSuccessUrls.length > 0) {
            return task.uxQuantiData.mobileSuccessUrls[0].url;
        } else {
            this.task.uxQuantiData = {
              desktopStartUrl: '',
              desktopSuccessUrls: [],
              mobileStartUrl: '',
              mobileSuccessUrls: [],
              maxTime: 1,
              minTime: 1
            }
            task.uxQuantiData.mobileSuccessUrls[0] = {name:'',url:''}
            return task.uxQuantiData.mobileSuccessUrls[0].url;
        }
      },
      uxQuantiTaskChange(){
        if(this.task.uxQuantiTask && this.task.uxQuantiData == undefined){
          this.task.uxQuantiData = {
              desktopStartUrl: '',
              desktopSuccessUrls: [],
              mobileStartUrl: '',
              mobileSuccessUrls: [],
              maxTime: 1,
              minTime: 1
            }
        }
        this.dataIsUpdated()
      },
      uxTaskChange(){
        if(this.task.uxTask && this.task.uxData == undefined){
          this.task.uxData = {
            initialUrl: '',
            recordScreen: false,
            recordMicrophone: false,
            recordWebcam: false,
            checkpoints: []
          }
        }
        this.dataIsUpdated()
      },
      uxClickTestTaskChange(){
        if(this.task.clickTestTask && this.task.clickTestData == undefined){
          this.task.clickTestData = {
            imageUrl:'',
            timeoutSeconds:0,
            message:'',
            successZonTL_X:0,
            successZoneTL_Y:0,
            successZoneBR_X:0,
            successZoneBR_Y:0
          }
        }
        this.dataIsUpdated()
      },
      changeRule(){
        this.dataIsUpdated()
      },
      addRule(question: any){
        addRule(this.navigationRules, question, this.task.taskNum)
        this.dataIsUpdated()
      },
      duplicateQuestion(question: any){
        duplicateQuestion(question, this.task.questions)
        this.dataIsUpdated()
      },
      canRemoveQuestion(questionIndex: any){
        canRemoveQuestion(questionIndex, this.task.questions, this.navigationRules, this.$t)
        this.dataIsUpdated()
      },
      removeQuestionValue(event: any, index: any){
        removeQuestionValue(this.task.questions, index, event)
        this.dataIsUpdated()
      },
      removeQuestionValueOption(event: any, index: any){
        removeQuestionValueOption(this.task.questions, index, event)
        this.dataIsUpdated()
      },
      reorderQuestions(){
        reorderQuestions(this.task.questions)
        this.dataIsUpdated()
      },
      addValue(question: any, addTxt: Boolean){
        addValue(question, addTxt)
        this.dataIsUpdated()
      },
      addOptionMatrix(question: any){
        addOptionMatrix(question)
        this.dataIsUpdated()
      },
      dataIsUpdated(){
        this.$emit('data-updated')
      },
      duplicateCheckpoint(checkpoint: any){
        const cp: any = checkpoint
        this.task.uxData.checkpoints.push(cp)
        this.dataIsUpdated()
      },  
      deleteCheckpoint(index: any){
        this.task.uxData.checkpoints.splice(index,1)
        this.dataIsUpdated()
      },
      hasErrors(){
        if(this.task.name == undefined || this.task.name.length < 3) return true
        if(this.task.description == undefined || this.task.description.length < 3) return true
        if(this.task.uxQuantiTask){
          const figmaDesktop: any = this.$refs.FigmaDesktop
          const figmaMobile: any = this.$refs.FigmaMobile
          if(figmaDesktop.hashErrors()) return true
          if(figmaMobile.hashErrors()) return true
        }
        if(this.task.uxTask){
          if(!this.task.uxData.recordWebcam && !this.task.uxData.recordMicrophone && !this.task.uxData.recordWebcam) return true
          if(this.task.uxData.initialUrl.length == 0 || this.uxUrlInitialError) true
          if(!this.task.uxData.onlyRecord){
            if(this.task.uxData.checkpoints.length == 0) return
          }
        }
        if(!this.task.uxQuantiTask && !this.task.uxTask && !this.task.clickTestTask && this.task.questions.length == 0) return true
        if(this.task.questions.length > 0){
          let error = false
          for(let index in this.task.questions){
            const question: any = this.$refs['Question-' + index]
            if(question.hasErrors()) error = true
          }
          if(error) return true
        }
        return false
      },
      loadCanvasData(){
        const canvas: any = document.getElementById('Canvas-Task-' + this.task.taskNum)
        const parentContainer: any = document.getElementById('CanvasParent-Task-' + this.task.taskNum);

        const ctx: any = canvas.getContext('2d');

        let imageWidth = this.imageHTML.width;
        let imageHeight = this.imageHTML.height;

        let parentWidth = parentContainer.clientWidth;
        let parentHeight = parentContainer.clientHeight;

        const scaleWidth = parentWidth / imageWidth;
        const scaleHeight = parentHeight / imageHeight;
        const scaleFactor = Math.min(scaleWidth, scaleHeight);

        if(scaleFactor < 1) {
          imageWidth = imageWidth * scaleFactor
          imageHeight = imageHeight * scaleFactor
        }

        canvas.width = imageWidth
        canvas.height = imageHeight

        ctx.fillStyle = 'rgba(129, 52, 225, 0.43)';
        ctx.strokeStyle = 'rgb(129, 52, 225)';
        ctx.lineWidth = 3;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        ctx.drawImage(this.imageHTML, 0, 0, canvas.width, canvas.height);

        const startX = this.task.clickTestData.successZoneTL_X * canvas.width
        const startY = this.task.clickTestData.successZoneTL_Y * canvas.height
        const endX = this.task.clickTestData.successZoneBR_X * canvas.width
        const endY = this.task.clickTestData.successZoneBR_Y * canvas.height

        ctx.fillRect(startX, startY, endX - startX, endY - startY);
        ctx.strokeRect(startX, startY, endX - startX, endY - startY);

        const additionalRectText = "AREA DE CLICK";

        ctx.fillStyle = 'rgb(129, 52, 225)';
        ctx.fillRect(startX - 2, startY - 22, 105, 22);

        ctx.fillStyle = 'white';
        ctx.font = '12px Arial';
        ctx.fillText(additionalRectText, startX + 5, startY - 8);
        
      },
      generateCanvas(){
        let vm = this
      
        const imageUrl = this.task.clickTestData.imageUrl;
        this.imageHTML = new Image();
        this.imageHTML.src = imageUrl;

        this.imageHTML.onload = function() {
          vm.loadCanvasData()
        };
      }
    },
    mounted(){
      const emitter: any = inject("emitter")
      emitter.on("save-clicktest-data", (event: any) => {
        this.destroyClickTestInstance()
        this.task.clickTestData = event;
        this.generateCanvas()
        this.dataIsUpdated()
      })
      if(this.hasClickTestImage) this.generateCanvas()
    }
  })
  