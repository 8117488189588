import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0710c956"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "StudyFigmaUrl" }
const _hoisted_2 = { class: "StudyFigmaUrl--GroupDevice" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "StudyFigmaUrl--Input" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "StudyFigmaUrl--GroupDevice" }
const _hoisted_8 = { class: "StudyFigmaUrl--Input" }
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuiteInput = _resolveComponent("SuiteInput")!
  const _component_Separation = _resolveComponent("Separation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('suite_study_figma_url_start')), 1),
      _createElementVNode("h3", {
        innerHTML: _ctx.$t('suite_study_figma_url_start_desc')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_SuiteInput, {
          modelValue: _ctx.startUrl,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.startUrl) = $event)),
          required: "",
          class: "Input--SurveyCreation",
          placeholder: ""
        }, null, 8, ["modelValue"]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('open-iframe', _ctx.startUrl)))
        }, _toDisplayString(_ctx.$t('suite_study_figma_test_url')), 1)
      ]),
      _withDirectives(_createElementVNode("p", {
        class: "StudyFigmaUrl--Error",
        innerHTML: _ctx.messageErrorUrl
      }, null, 8, _hoisted_5), [
        [_vShow, _ctx.startUrlValidation.error]
      ]),
      _withDirectives(_createElementVNode("p", {
        class: "StudyFigmaUrl--Success",
        innerHTML: _ctx.messageCorrectUrl
      }, null, 8, _hoisted_6), [
        [_vShow, _ctx.startUrlValidation.success]
      ])
    ]),
    _createVNode(_component_Separation),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('suite_study_figma_url_finished')), 1),
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('suite_study_figma_url_finished_desc')), 1),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_SuiteInput, {
          modelValue: _ctx.successUrl,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.successUrl) = $event)),
          required: "",
          class: "Input--SurveyCreation"
        }, null, 8, ["modelValue"]),
        _createElementVNode("button", {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('open-iframe', _ctx.successUrl)))
        }, _toDisplayString(_ctx.$t('suite_study_figma_test_url')), 1)
      ]),
      _withDirectives(_createElementVNode("p", {
        class: "StudyFigmaUrl--Error",
        innerHTML: _ctx.messageErrorUrl
      }, null, 8, _hoisted_9), [
        [_vShow, _ctx.successUrlValidation.error]
      ]),
      _withDirectives(_createElementVNode("p", {
        class: "StudyFigmaUrl--Success",
        innerHTML: _ctx.messageCorrectUrl
      }, null, 8, _hoisted_10), [
        [_vShow, _ctx.successUrlValidation.success]
      ])
    ])
  ]))
}